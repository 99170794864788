export const ENV = {
  EMAILJS: {
    SERVICE_ID: import.meta.env.VITE_EMAILJS_SERVICE_ID,
    TEMPLATE_ID: import.meta.env.VITE_EMAILJS_TEMPLATE_ID,
    PUBLIC_KEY: import.meta.env.VITE_EMAILJS_PUBLIC_KEY,
  },
  RECAPTCHA: {
    SITE_KEY: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
    SECRET_KEY: import.meta.env.VITE_RECAPTCHA_SECRET_KEY,
  }
} as const;

// Type guard to ensure environment variables are defined
Object.entries(ENV).forEach(([category, values]) => {
  Object.entries(values).forEach(([key, value]) => {
    if (!value) {
      throw new Error(`Missing environment variable for ${category}.${key}`);
    }
  });
});