import React from 'react';
import { Shield, Lock, Eye, Database, ArrowRight, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

export default function PrivacyPolicy() {
  return (
    <>
      <SEO 
        title="Privacy Policy"
        description="Our commitment to protecting your privacy and personal data in compliance with GDPR and other data protection regulations."
        keywords={[
          'Privacy Policy',
          'GDPR Compliance',
          'Data Protection',
          'Privacy Rights',
          'Data Security'
        ]}
      />
      
      <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-24 pb-20">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header Section */}
          <div className="text-center mb-12">
            <div className="flex justify-center mb-6">
              <Shield className="h-16 w-16 text-teal-500" />
            </div>
            <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-purple-400">
              Privacy Policy
            </h1>
            <p className="text-gray-400">Last Updated: March 15, 2024</p>
          </div>

          {/* Quick Links - Centered Grid */}
          <div className="max-w-3xl mx-auto mb-12">
            <div className="grid grid-cols-3 gap-6">
              <div className="glass-card p-6 rounded-xl backdrop-blur-md text-center">
                <Lock className="h-6 w-6 text-teal-500 mx-auto mb-3" />
                <span className="text-gray-300 text-sm">GDPR Compliant</span>
              </div>
              <div className="glass-card p-6 rounded-xl backdrop-blur-md text-center">
                <Eye className="h-6 w-6 text-teal-500 mx-auto mb-3" />
                <span className="text-gray-300 text-sm">Data Transparency</span>
              </div>
              <div className="glass-card p-6 rounded-xl backdrop-blur-md text-center">
                <Database className="h-6 w-6 text-teal-500 mx-auto mb-3" />
                <span className="text-gray-300 text-sm">Data Protection</span>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="space-y-8">
            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">1. Introduction and Key Information</h2>
              <div className="text-gray-300 space-y-4">
                <p>
                  TacticalSolutions.ai ("we," "our," or "us") is committed to protecting your privacy and personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services, website, and applications (collectively, the "Services").
                </p>
                <p>
                  We act as a data controller and are responsible for your personal data in accordance with:
                </p>
                <ul className="list-disc list-inside ml-4 space-y-2">
                  <li>The UK General Data Protection Regulation (UK GDPR)</li>
                  <li>The EU General Data Protection Regulation (EU GDPR)</li>
                  <li>The UK Data Protection Act 2018</li>
                  <li>The Privacy and Electronic Communications Regulations (PECR)</li>
                </ul>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">2. Data Controller Information</h2>
              <div className="text-gray-300 space-y-4">
                <p>
                  TacticalSolutions.ai is registered as a data controller with the Information Commissioner's Office (ICO).
                </p>
                <div className="mt-4">
                  <p><strong className="text-teal-400">Data Controller:</strong> Anthony Keen</p>
                  <p><strong className="text-teal-400">Address:</strong> London, United Kingdom</p>
                  <p><strong className="text-teal-400">Email:</strong> privacy@tacticalsolutions.ai</p>
                  <p><strong className="text-teal-400">DPO Contact:</strong> dpo@tacticalsolutions.ai</p>
                </div>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">3. Legal Basis for Processing</h2>
              <div className="text-gray-300 space-y-4">
                <p>We process your personal data under the following legal bases:</p>
                <ul className="list-disc list-inside ml-4 space-y-2">
                  <li><strong className="text-teal-400">Consent:</strong> Where you have explicitly agreed to the processing</li>
                  <li><strong className="text-teal-400">Contract:</strong> Where processing is necessary for the performance of a contract</li>
                  <li><strong className="text-teal-400">Legal Obligation:</strong> Where processing is necessary for compliance with a legal obligation</li>
                  <li><strong className="text-teal-400">Legitimate Interests:</strong> Where processing is necessary for our legitimate interests</li>
                </ul>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">4. Personal Data We Collect</h2>
              <div className="text-gray-300 space-y-4">
                <h3 className="text-xl font-semibold text-teal-400 mb-4">4.1 Information You Provide</h3>
                <ul className="list-disc list-inside ml-4 space-y-2">
                  <li>Name and contact details</li>
                  <li>Company information</li>
                  <li>Communication preferences</li>
                  <li>Service usage information</li>
                  <li>Payment information</li>
                </ul>

                <h3 className="text-xl font-semibold text-teal-400 mb-4 mt-6">4.2 Automatically Collected Information</h3>
                <ul className="list-disc list-inside ml-4 space-y-2">
                  <li>Device and browser information</li>
                  <li>IP address and location data</li>
                  <li>Usage data and analytics</li>
                  <li>Cookies and similar technologies</li>
                </ul>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">5. Cookie Policy</h2>
              <div className="text-gray-300 space-y-4">
                <p>We use cookies and similar tracking technologies to:</p>
                <ul className="list-disc list-inside ml-4 space-y-2">
                  <li><strong className="text-teal-400">Essential Cookies:</strong> Required for basic website functionality</li>
                  <li><strong className="text-teal-400">Analytics Cookies:</strong> Help us understand how visitors use our website</li>
                  <li><strong className="text-teal-400">Marketing Cookies:</strong> Used to deliver relevant advertisements (with consent)</li>
                </ul>
                <p className="mt-4">
                  You can manage your cookie preferences through our cookie consent banner or your browser settings.
                </p>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">6. Your Rights Under GDPR</h2>
              <div className="text-gray-300 space-y-4">
                <p>Under data protection laws, you have the following rights:</p>
                <ul className="list-disc list-inside ml-4 space-y-2">
                  <li>Right to access your personal data</li>
                  <li>Right to rectification of inaccurate data</li>
                  <li>Right to erasure ("right to be forgotten")</li>
                  <li>Right to restrict processing</li>
                  <li>Right to data portability</li>
                  <li>Right to object to processing</li>
                  <li>Rights related to automated decision-making</li>
                  <li>Right to withdraw consent</li>
                </ul>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">7. International Data Transfers</h2>
              <div className="text-gray-300 space-y-4">
                <p>
                  When we transfer personal data outside the UK or EEA, we ensure appropriate safeguards are in place through:
                </p>
                <ul className="list-disc list-inside ml-4 space-y-2">
                  <li>Standard Contractual Clauses (SCCs)</li>
                  <li>Adequacy decisions</li>
                  <li>Binding Corporate Rules</li>
                  <li>Other legally approved transfer mechanisms</li>
                </ul>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">8. Data Security</h2>
              <div className="text-gray-300 space-y-4">
                <p>We implement appropriate technical and organizational measures to protect your data, including:</p>
                <ul className="list-disc list-inside ml-4 space-y-2">
                  <li>Encryption of data in transit and at rest</li>
                  <li>Regular security assessments</li>
                  <li>Access controls and authentication</li>
                  <li>Staff training on data protection</li>
                  <li>Incident response procedures</li>
                </ul>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">9. Data Retention</h2>
              <div className="text-gray-300 space-y-4">
                <p>
                  We retain personal data only for as long as necessary to fulfill the purposes for which it was collected,
                  including legal, accounting, or reporting requirements.
                </p>
                <p>
                  When data is no longer needed, it is securely deleted or anonymized.
                </p>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">10. Children's Privacy</h2>
              <div className="text-gray-300">
                <p>
                  Our Services are not intended for children under 16. We do not knowingly collect or process
                  personal data from children under 16. If we become aware that we have collected personal data
                  from a child under 16, we will take steps to delete such information.
                </p>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">11. Changes to This Policy</h2>
              <div className="text-gray-300">
                <p>
                  We may update this Privacy Policy from time to time. We will notify you of any changes by posting
                  the new Privacy Policy on this page and updating the "Last Updated" date.
                </p>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <h2 className="text-2xl font-bold text-white mb-6">12. Contact Us</h2>
              <div className="text-gray-300 space-y-4">
                <p>
                  For any questions about this Privacy Policy or our data practices, please contact us:
                </p>
                <div className="mt-4 space-y-2">
                  <p><strong className="text-teal-400">Email:</strong> privacy@tacticalsolutions.ai</p>
                  <p><strong className="text-teal-400">DPO:</strong> dpo@tacticalsolutions.ai</p>
                  <p><strong className="text-teal-400">Address:</strong> London, United Kingdom</p>
                </div>
                <p className="mt-6">
                  You have the right to make a complaint at any time to the Information Commissioner's Office (ICO),
                  the UK supervisory authority for data protection issues (www.ico.org.uk).
                </p>
              </div>
            </div>

            <div className="glass-card p-8 rounded-xl backdrop-blur-md">
              <div className="text-center">
                <h2 className="text-2xl font-bold text-white mb-6">Need More Information?</h2>
                <p className="text-gray-300 mb-8">
                  If you have any questions about our privacy practices or would like to exercise your rights,
                  please don't hesitate to contact us.
                </p>
                <div className="flex flex-col sm:flex-row justify-center gap-4">
                  <Link
                    to="/contact"
                    className="inline-flex items-center px-6 py-3 text-sm font-medium rounded-lg text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200"
                  >
                    <Mail className="w-4 h-4 mr-2" />
                    Contact Us
                  </Link>
                  <Link
                    to="/terms-of-service"
                    className="inline-flex items-center px-6 py-3 text-sm font-medium rounded-lg text-white hover:text-teal-400 border border-gray-700 hover:border-teal-500/50 transition-all duration-200"
                  >
                    <ArrowRight className="w-4 h-4 mr-2" />
                    Terms of Service
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}