import React, { useEffect, useState } from 'react';
import { Cookie, X, Shield, Settings } from 'lucide-react';
import { Link } from 'react-router-dom';
import { getCookie, setCookie } from '../utils/cookies';

export default function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const hasConsented = getCookie('cookieConsent');
      if (!hasConsented) {
        setIsVisible(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleAcceptAll = () => {
    setCookie('cookieConsent', 'all', {
      expires: 365,
      path: '/',
      sameSite: 'Lax',
      secure: true
    });
    setIsVisible(false);
    window.dispatchEvent(new Event('cookieConsentUpdated'));
  };

  const handleAcceptEssential = () => {
    setCookie('cookieConsent', 'essential', {
      expires: 365,
      path: '/',
      sameSite: 'Lax',
      secure: true
    });
    setIsVisible(false);
    window.dispatchEvent(new Event('cookieConsentUpdated'));
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-50 px-4 pb-4 sm:px-6 lg:px-8">
      <div className="animate-slide-up">
        <div className="max-w-7xl mx-auto">
          <div className="relative glass-card p-6 backdrop-blur-xl rounded-2xl shadow-2xl border border-white/10">
            {/* Decorative gradient effects */}
            <div className="absolute inset-0 rounded-2xl overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-br from-teal-500/10 via-purple-500/10 to-blue-500/10" />
              <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-teal-500/20 to-transparent" />
              <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-purple-500/20 to-transparent" />
            </div>

            <div className="relative flex flex-col md:flex-row items-start md:items-center gap-6">
              {/* Icon and Text Section */}
              <div className="flex-1 flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-teal-500/20 to-purple-500/20 p-2.5 flex items-center justify-center">
                    <Cookie className="w-full h-full text-teal-400" />
                  </div>
                </div>
                
                <div className="flex-1 min-w-0">
                  <h3 className="text-lg font-semibold text-white mb-2">Cookie Preferences</h3>
                  <p className="text-gray-300 text-sm leading-relaxed">
                    We use cookies to enhance your browsing experience and analyze our traffic. 
                    By clicking "Accept All", you consent to our use of cookies. Read our{' '}
                    <Link to="/privacy-policy" className="text-teal-400 hover:text-teal-300 underline">
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link to="/terms-of-service" className="text-teal-400 hover:text-teal-300 underline">
                      Terms of Service
                    </Link>{' '}
                    to learn more.
                  </p>
                </div>
              </div>

              {/* Buttons Section */}
              <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-3 md:gap-4">
                <button
                  onClick={handleAcceptEssential}
                  className="inline-flex items-center justify-center px-6 py-2.5 text-sm font-medium rounded-lg text-white hover:text-teal-400 border border-gray-700 hover:border-teal-500/50 transition-all duration-200 group"
                >
                  <Shield className="w-4 h-4 mr-2 transition-transform group-hover:scale-110" />
                  Essential Only
                </button>
                <button
                  onClick={handleAcceptAll}
                  className="inline-flex items-center justify-center px-6 py-2.5 text-sm font-medium rounded-lg text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200 shadow-lg shadow-teal-500/20 hover:shadow-teal-500/30 group"
                >
                  <Settings className="w-4 h-4 mr-2 transition-transform group-hover:scale-110" />
                  Accept All
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}