import React from 'react';
import { ArrowRight, Sparkles, Zap, Swords, Users, Calculator } from 'lucide-react';
import { Link } from 'react-router-dom';
import BuckyballBackground from './BuckyballBackground';
import ChatConversation from './chat/ChatConversation';

export default function Hero() {
  return (
    <div className="relative min-h-screen flex items-center">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900" />
      <BuckyballBackground />
      
      <div className="absolute inset-0 bg-gradient-to-r from-gray-900/80 via-gray-900/50 to-transparent" />
      <div className="absolute inset-0 bg-gradient-to-t from-gray-900/90 via-gray-900/50 to-transparent" />
      
      <div className="absolute inset-0">
        {[...Array(20)].map((_, i) => {
          const Icon = [Swords, Users, Zap][i % 3];
          return (
            <div
              key={i}
              className="absolute animate-float"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: 0.1,
              }}
            >
              <Icon className="text-teal-500" size={Math.random() * 16 + 8} />
            </div>
          );
        })}
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-8">
            <div>
              <h1 className="text-5xl md:text-7xl font-bold leading-tight">
                <span className="text-gradient relative z-10 [text-shadow:_0_1px_12px_rgb(0_168_204_/_20%)]">
                  Empowering
                </span>
                <span className="block text-white [text-shadow:_0_1px_12px_rgb(0_0_0_/_50%)]">
                  Businesses with
                </span>
                <span className="text-gradient relative z-10 [text-shadow:_0_1px_12px_rgb(0_168_204_/_20%)]">
                  Accessible AI
                </span>
              </h1>
              <p className="text-xl text-gray-300 max-w-2xl mt-6 [text-shadow:_0_1px_12px_rgb(0_0_0_/_50%)]">
                Leveling the playing field through innovative AI-driven automation and operational optimization for businesses of all sizes.
              </p>
            </div>

            <div className="flex flex-col sm:flex-row gap-6">
              <Link 
                to="/contact" 
                className="group inline-flex items-center px-8 py-3 text-lg font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200"
              >
                <span className="relative z-10">Start Your Journey</span>
                <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
              </Link>
              
              <Link 
                to="/roi-calculator" 
                className="group inline-flex items-center px-8 py-3 text-lg font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-purple-500 to-purple-600 hover:from-purple-600 hover:to-purple-700 transition-all duration-200"
              >
                <Calculator className="h-5 w-5 mr-2" />
                <span className="relative z-10">Calculate ROI</span>
                <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
              </Link>
            </div>

            <div className="flex flex-wrap gap-6 text-gray-400">
              <div className="flex items-center">
                <Swords className="h-5 w-5 text-teal-500 mr-2" />
                <span>Ethical AI</span>
              </div>
              <div className="flex items-center">
                <Users className="h-5 w-5 text-teal-500 mr-2" />
                <span>All Business Sizes</span>
              </div>
              <div className="flex items-center">
                <Zap className="h-5 w-5 text-teal-500 mr-2" />
                <span>Quick Integration</span>
              </div>
            </div>
          </div>

          <ChatConversation />
        </div>
      </div>
    </div>
  );
}