import { marked } from 'marked';
import type { BlogPost } from '../types/blog';

// This would typically fetch from an API or CMS
export async function fetchBlogPosts(): Promise<BlogPost[]> {
  // Simulated API call
  return [
    {
      slug: 'getting-started-with-ai-automation',
      title: 'Getting Started with AI Automation',
      excerpt: 'Learn how to implement AI automation in your business operations...',
      content: marked(`
# Getting Started with AI Automation

AI automation is revolutionizing how businesses operate...

## Key Benefits

1. Increased Efficiency
2. Reduced Errors
3. Cost Savings

## Implementation Steps

1. Assess Your Needs
2. Choose the Right Tools
3. Train Your Team
4. Monitor and Optimize
      `),
      publishedAt: '2024-03-15T10:00:00Z',
      coverImage: 'https://images.unsplash.com/photo-1485827404703-89b55fcc595e',
      tags: ['AI', 'Automation', 'Business'],
      author: {
        name: 'Anthony Keen',
        role: 'Founder & CEO',
        avatar: 'https://stackblitz.com/storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBelZCR2c9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--bcf220492d00a12bb7a39f9613c9115edaa1db69/AntKeen1.png'
      }
    }
    // Add more blog posts here
  ];
}

export async function fetchBlogPost(slug: string): Promise<BlogPost | null> {
  const posts = await fetchBlogPosts();
  return posts.find(post => post.slug === slug) || null;
}