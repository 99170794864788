import React, { useState } from 'react';
import { ArrowRight, X } from 'lucide-react';

interface Position {
  title: string;
  department: string;
  location: string;
  type: string;
  description: string;
  responsibilities: {
    category: string;
    items: string[];
  }[];
  requirements: {
    category: string;
    items: string[];
  }[];
  benefits: string[];
}

const positions: Position[] = [
  {
    title: 'AI Automation & Backend Developer',
    department: 'Engineering',
    location: 'Remote',
    type: 'Full-time',
    description: 'We are seeking a skilled AI Automation & Backend Developer with a passion for building seamless automated solutions.',
    responsibilities: [
      {
        category: 'AI Automation Solutions',
        items: [
          'Design, implement, and refine AI-driven workflows that streamline processes for clients',
          'Work with tools such as Make.com, n8n, and Supabase to develop and maintain automated solutions'
        ]
      },
      {
        category: 'Backend Development',
        items: [
          'Build and maintain robust server-side components, APIs, and databases',
          'Handle tasks such as data modelling, authentication, and performance optimisation',
          'Collaborate with frontend and DevOps teams to integrate backend services effectively'
        ]
      },
      {
        category: 'Project Collaboration',
        items: [
          'Partner with cross-functional teams to gather requirements and manage project timelines',
          'Communicate clearly with non-technical stakeholders to translate complex ideas'
        ]
      },
      {
        category: 'Quality Assurance',
        items: [
          'Test and troubleshoot automation workflows, ensuring reliability and security',
          'Enforce coding best practices and conduct thorough reviews'
        ]
      },
      {
        category: 'Client Engagement',
        items: [
          'Provide technical support, lead workshops or demonstrations',
          'Collaborate directly with clients to tailor solutions to their specific needs'
        ]
      },
      {
        category: 'Continuous Improvement',
        items: [
          'Stay updated on the latest AI, automation, and backend development trends',
          'Proactively recommend process enhancements and new features'
        ]
      }
    ],
    requirements: [
      {
        category: 'Technical Expertise',
        items: [
          'Hands-on experience with no-code/low-code platforms (Make.com, n8n, Supabase)',
          'Proficiency with RESTful APIs, database design, and server-side scripting',
          'Familiarity with machine learning libraries, APIs, or large language models is a bonus'
        ]
      },
      {
        category: 'Problem-Solving',
        items: [
          'Ability to dissect complex technical challenges into clear, scalable solutions'
        ]
      },
      {
        category: 'Collaboration',
        items: [
          'Strong communication skills with a knack for explaining technical ideas',
          'Enjoys working in a team-focused environment while independently driving projects'
        ]
      },
      {
        category: 'Adaptability',
        items: [
          'Comfortable in a dynamic, fast-paced setting',
          'Ready to explore new tools, frameworks, and methodologies'
        ]
      },
      {
        category: 'Organisation & Time Management',
        items: [
          'Proven track record of managing multiple projects simultaneously',
          'Meeting deadlines without compromising quality'
        ]
      }
    ],
    benefits: [
      'Competitive Compensation: A salary package reflecting your experience and impact',
      'Innovative Environment: Work on AI automation and backend projects at the forefront',
      'Flexible Working: A remote-friendly culture emphasising work-life balance',
      'Growth Opportunities: Continuous learning with access to professional development'
    ]
  }
];

export default function OpenPositions() {
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(null);
  const [activeTab, setActiveTab] = useState('responsibilities');

  return (
    <section className="py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center mb-12">
          <span className="text-gradient">Open Positions</span>
        </h2>

        <div className="grid gap-6">
          {positions.map((position, index) => (
            <div
              key={index}
              className="glass-card p-6 rounded-xl backdrop-blur-md hover:scale-[1.02] transition-transform duration-300"
            >
              <div className="flex flex-col md:flex-row md:items-center justify-between">
                <div className="mb-4 md:mb-0">
                  <h3 className="text-xl font-bold text-white mb-2">{position.title}</h3>
                  <div className="flex flex-wrap gap-2 mb-2">
                    <span className="px-3 py-1 rounded-full text-sm bg-teal-500/10 text-teal-400">
                      {position.department}
                    </span>
                    <span className="px-3 py-1 rounded-full text-sm bg-purple-500/10 text-purple-400">
                      {position.location}
                    </span>
                    <span className="px-3 py-1 rounded-full text-sm bg-blue-500/10 text-blue-400">
                      {position.type}
                    </span>
                  </div>
                  <p className="text-gray-300">{position.description}</p>
                </div>
                <button 
                  onClick={() => setSelectedPosition(position)}
                  className="inline-flex items-center px-8 py-3 text-lg font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200"
                >
                  <span>Apply Now</span>
                  <ArrowRight className="ml-2 h-4 w-4" />
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Application Modal */}
        {selectedPosition && (
          <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center">
            <div className="fixed inset-0 bg-gray-900/75 backdrop-blur-sm"></div>
            
            <div className="glass-card rounded-xl shadow-xl transform transition-all w-full max-w-4xl mx-4 my-8 relative z-50 max-h-[90vh] flex flex-col">
              <div className="p-8">
                <div className="flex justify-between items-start mb-8">
                  <div>
                    <h3 className="text-3xl font-bold text-white mb-2">
                      Apply for {selectedPosition.title}
                    </h3>
                    <p className="text-gray-400">
                      {selectedPosition.department} · {selectedPosition.location} · {selectedPosition.type}
                    </p>
                  </div>
                  <button
                    onClick={() => setSelectedPosition(null)}
                    className="text-gray-400 hover:text-white transition-colors p-1"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>

                {/* Tab Navigation */}
                <div className="flex space-x-4 mb-6 border-b border-gray-700">
                  {[
                    { id: 'responsibilities', label: 'Responsibilities' },
                    { id: 'requirements', label: 'Requirements' },
                    { id: 'benefits', label: 'Benefits' }
                  ].map((tab) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`px-4 py-2 text-sm font-medium transition-colors relative ${
                        activeTab === tab.id
                          ? 'text-teal-400'
                          : 'text-gray-400 hover:text-gray-300'
                      }`}
                    >
                      {tab.label}
                      {activeTab === tab.id && (
                        <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-teal-500" />
                      )}
                    </button>
                  ))}
                </div>

                {/* Tab Content */}
                <div className="overflow-y-auto custom-scrollbar pr-4" style={{ maxHeight: 'calc(70vh - 200px)' }}>
                  {activeTab === 'responsibilities' && (
                    <div className="space-y-6">
                      {selectedPosition.responsibilities.map((section, idx) => (
                        <div key={idx} className="mb-4">
                          <h5 className="text-lg font-medium text-teal-400 mb-2">{section.category}</h5>
                          <ul className="list-disc list-inside text-gray-300 space-y-2">
                            {section.items.map((item, i) => (
                              <li key={i}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  )}

                  {activeTab === 'requirements' && (
                    <div className="space-y-6">
                      {selectedPosition.requirements.map((section, idx) => (
                        <div key={idx} className="mb-4">
                          <h5 className="text-lg font-medium text-teal-400 mb-2">{section.category}</h5>
                          <ul className="list-disc list-inside text-gray-300 space-y-2">
                            {section.items.map((item, i) => (
                              <li key={i}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  )}

                  {activeTab === 'benefits' && (
                    <div className="space-y-6">
                      <ul className="list-disc list-inside text-gray-300 space-y-2">
                        {selectedPosition.benefits.map((benefit, i) => (
                          <li key={i}>{benefit}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                
                <div className="text-center mt-8 pt-8 border-t border-gray-700">
                  <div className="p-4 bg-teal-500/10 rounded-lg">
                    <p className="text-teal-400 font-semibold">IMPORTANT</p>
                    <p className="text-gray-300">
                      ALL applicants must apply through these assessments: 
                      <a 
                        href="https://app.testgorilla.com/s/kzsp7u5d" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-teal-400 hover:text-teal-300 ml-1"
                      >
                        app.testgorilla.com/s/kzsp7u5d
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex justify-center mt-8">
                  <a
                    href="https://app.testgorilla.com/s/kzsp7u5d"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-8 py-3 text-lg font-medium rounded-md text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200"
                  >
                    <span>Start Assessment</span>
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}