import type { FormData, EmailTemplateParams } from './types';

export function formatSolutions(solutions: string[]): string {
  return solutions.length > 0 ? solutions.join(', ') : 'None selected';
}

export function mapFormDataToEmailParams(formData: FormData): EmailTemplateParams {
  return {
    user_name: formData.fullName,
    user_email: formData.email,
    user_phone: formData.phone || 'Not provided',
    company: formData.companyName || 'Not provided',
    query_type: formData.queryType || 'Not specified',
    solutions: formatSolutions(formData.solutions),
    current_tools: formData.currentTools || 'Not specified',
    message: formData.projectDescription,
    timeline: formData.timeline || 'Not specified',
    budget: formData.budget || 'Not specified',
    data_subject: formData.dataSubject || 'Not applicable',
    request_type: formData.requestType || 'Not applicable',
    request_details: formData.requestDetails || 'Not provided',
    additional_info: formData.additionalInfo || 'No additional information provided'
  };
}