import React, { useState, useRef, useEffect } from 'react';
import { Mail, CheckCircle, AlertCircle } from 'lucide-react';
import emailjs from '@emailjs/browser';
import { ENV } from '../../utils/env';
import ContactInfo from './ContactInfo';
import ProjectDetails from './ProjectDetails';
import TechnicalRequirements from './TechnicalRequirements';
import ProjectScope from './ProjectScope';
import DPOInquiry from './DPOInquiry';
import AdditionalInfo from './AdditionalInfo';
import { mapFormDataToEmailParams } from './utils';
import type { FormData, ValidationErrors } from './types';

export default function ContactForm() {
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    companyName: '',
    email: '',
    phone: '',
    queryType: '',
    solutions: [],
    currentTools: '',
    projectDescription: '',
    timeline: '',
    budget: '',
    dataSubject: '',
    requestType: '',
    requestDetails: '',
    additionalInfo: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    // Load reCAPTCHA v3
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${ENV.RECAPTCHA.SITE_KEY}&onload=onRecaptchaLoad`;
    document.body.appendChild(script);

    // Define callback for when reCAPTCHA loads
    (window as any).onRecaptchaLoad = () => {
      console.log('reCAPTCHA loaded successfully');
    };

    return () => {
      document.body.removeChild(script);
      delete (window as any).onRecaptchaLoad;
    };
  }, []);

  const executeRecaptcha = async () => {
    if (!(window as any).grecaptcha) {
      console.error('reCAPTCHA not loaded');
      return null;
    }

    try {
      const token = await (window as any).grecaptcha.execute(ENV.RECAPTCHA.SITE_KEY, {
        action: 'contact_form_submit'
      });
      setRecaptchaToken(token);
      return token;
    } catch (error) {
      console.error('reCAPTCHA error:', error);
      return null;
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Clear validation error when field is modified
    if (validationErrors[name as keyof ValidationErrors]) {
      setValidationErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  const validateForm = (): boolean => {
    const errors: ValidationErrors = {};
    
    if (!formData.fullName.trim()) {
      errors.fullName = 'Full name is required';
    }

    if (!formData.companyName.trim()) {
      errors.companyName = 'Company name is required';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (!formData.phone.trim()) {
      errors.phone = 'Phone number is required';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus('idle');
    setErrorMessage('');

    try {
      // Execute reCAPTCHA and get token
      const token = await executeRecaptcha();
      if (!token) {
        setSubmitStatus('error');
        setErrorMessage('Could not verify reCAPTCHA. Please try again.');
        return;
      }
      
      if (!formRef.current) return;

      // Map form data to email template parameters
      const emailParams = mapFormDataToEmailParams(formData);
      emailParams.recaptcha_token = token;

      // Send email using EmailJS
      await emailjs.send(
        ENV.EMAILJS.SERVICE_ID,
        ENV.EMAILJS.TEMPLATE_ID,
        emailParams,
        ENV.EMAILJS.PUBLIC_KEY
      );

      setSubmitStatus('success');
      
      // Reset form
      setFormData({
        fullName: '',
        companyName: '',
        email: '',
        phone: '',
        queryType: '',
        solutions: [],
        currentTools: '',
        projectDescription: '',
        timeline: '',
        budget: '',
        dataSubject: '',
        requestType: '',
        requestDetails: '',
        additionalInfo: ''
      });

    } catch (error) {
      setSubmitStatus('error');
      setErrorMessage(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFormSections = () => {
    switch (formData.queryType) {
      case 'sales':
      case 'general':
        return (
          <>
            <ProjectScope formData={formData} handleChange={handleChange} />
            <TechnicalRequirements formData={formData} handleChange={handleChange} />
          </>
        );
      case 'support':
        return (
          <TechnicalRequirements formData={formData} handleChange={handleChange} />
        );
      case 'dpo':
        return (
          <DPOInquiry formData={formData} handleChange={handleChange} />
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit} ref={formRef} className="space-y-8" noValidate>
      <div className="space-y-8">
        <ContactInfo 
          formData={formData} 
          handleChange={handleChange} 
          errors={validationErrors}
        />
        <ProjectDetails formData={formData} handleChange={handleChange} />
        {renderFormSections()}
        <AdditionalInfo formData={formData} handleChange={handleChange} />
      </div>

      <div className="text-center pt-8 border-t border-gray-700">
        <button
          type="submit"
          disabled={isSubmitting}
          className={`inline-flex items-center px-8 py-3 text-lg font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200 ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Submitting...
            </span>
          ) : (
            'Submit Inquiry'
          )}
        </button>

        {submitStatus === 'success' && (
          <div className="mt-4 text-green-400 flex items-center justify-center">
            <CheckCircle className="w-5 h-5 mr-2" />
            <span>Form submitted successfully!</span>
          </div>
        )}

        {submitStatus === 'error' && (
          <div className="mt-4 text-red-400 flex items-center justify-center">
            <AlertCircle className="w-5 h-5 mr-2" />
            <span>{errorMessage || 'An error occurred. Please try again.'}</span>
          </div>
        )}

        <p className="mt-6 text-sm text-gray-400">
          By submitting this form, you agree to our privacy policy and terms of service.
        </p>
        <div className="flex items-center justify-center mt-6 text-gray-300">
          <Mail className="w-5 h-5 mr-2" />
          <span>Need immediate assistance? Email </span>
          <a 
            href="mailto:support@tacticalsolutions.ai" 
            className="ml-1 text-teal-400 hover:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-gray-900 rounded"
          >
            support@tacticalsolutions.ai
          </a>
        </div>
      </div>
    </form>
  );
}