import React from 'react';
import type { ValidationErrors } from './types';

interface ContactInfoProps {
  formData: {
    fullName: string;
    companyName: string;
    email: string;
    phone: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: ValidationErrors;
}

export default function ContactInfo({ formData, handleChange, errors }: ContactInfoProps) {
  return (
    <div className="glass-card p-8 rounded-xl backdrop-blur-md">
      <h2 className="text-2xl font-bold text-white mb-6 text-center">Contact Information</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-2xl mx-auto">
        <div>
          <label htmlFor="fullName" className="block text-sm font-medium text-gray-300 mb-2 text-center">
            Full Name*
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            className={`w-full px-4 py-3 rounded-md bg-gray-800 border text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent text-center ${
              errors?.fullName ? 'border-red-500' : 'border-gray-700'
            }`}
          />
          {errors?.fullName && (
            <p className="mt-1 text-red-400 text-sm text-center">{errors.fullName}</p>
          )}
        </div>
        <div>
          <label htmlFor="companyName" className="block text-sm font-medium text-gray-300 mb-2 text-center">
            Company Name*
          </label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
            className={`w-full px-4 py-3 rounded-md bg-gray-800 border text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent text-center ${
              errors?.companyName ? 'border-red-500' : 'border-gray-700'
            }`}
          />
          {errors?.companyName && (
            <p className="mt-1 text-red-400 text-sm text-center">{errors.companyName}</p>
          )}
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2 text-center">
            Email Address*
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className={`w-full px-4 py-3 rounded-md bg-gray-800 border text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent text-center ${
              errors?.email ? 'border-red-500' : 'border-gray-700'
            }`}
          />
          {errors?.email && (
            <p className="mt-1 text-red-400 text-sm text-center">{errors.email}</p>
          )}
        </div>
        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-300 mb-2 text-center">
            Phone Number*
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className={`w-full px-4 py-3 rounded-md bg-gray-800 border text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent text-center ${
              errors?.phone ? 'border-red-500' : 'border-gray-700'
            }`}
          />
          {errors?.phone && (
            <p className="mt-1 text-red-400 text-sm text-center">{errors.phone}</p>
          )}
        </div>
      </div>
    </div>
  );
}