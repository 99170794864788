import React, { useState, useRef, useEffect } from 'react';
import { Bot, Clock, Loader } from 'lucide-react';
import { conversations } from './conversations';

interface Message {
  isBot: boolean;
  text: string;
}

interface TypingIndicatorProps {
  className?: string;
}

function TypingIndicator({ className = "" }: TypingIndicatorProps) {
  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <Loader className="h-4 w-4 text-teal-500 animate-spin" />
      <span className="text-sm text-gray-400">AI Assistant is typing...</span>
    </div>
  );
}

interface ChatProps {
  conversation?: Message[];
}

function Message({ text, isBot }: { text: string; isBot?: boolean }) {
  return (
    <div className={`flex ${isBot ? 'justify-start' : 'justify-end'}`}>
      <div className={`max-w-[80%] p-3 rounded-lg ${
        isBot
          ? 'bg-gray-700/50 text-white'
          : 'bg-teal-500/20 text-teal-300'
      }`}>
        {text}
      </div>
    </div>
  );
}

export default function ChatConversation({ conversation }: ChatProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [conversationIndex, setConversationIndex] = useState(0);
  const chatRef = useRef<HTMLDivElement>(null);
  
  // Select a random conversation if none provided
  const selectedConversation = conversation || conversations[Math.floor(Math.random() * conversations.length)];

  // Effect to cycle through conversations
  useEffect(() => {
    const cycleTimer = setInterval(() => {
      setConversationIndex(prev => (prev + 1) % conversations.length);
      setMessages([]);
      setCurrentIndex(0);
    }, 30000); // Cycle every 30 seconds

    return () => clearInterval(cycleTimer);
  }, []);

  useEffect(() => {
    if (currentIndex < selectedConversation.length) {
      setIsTyping(true);
      const typingTimer = setTimeout(() => {
        setIsTyping(false);
        setMessages(prev => [...prev, selectedConversation[currentIndex]]);
        
        // Add delay before starting next message
        const nextMessageTimer = setTimeout(() => {
          setCurrentIndex(prev => prev + 1);
          
          // Scroll to bottom
          if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
          }
        }, 500);

        return () => clearTimeout(nextMessageTimer);
      }, currentIndex === 0 ? 1000 : Math.random() * 1000 + 1000); // Random typing time between 1-2 seconds

      return () => clearTimeout(typingTimer);
    }
  }, [currentIndex, selectedConversation]);

  return (
    <div className="relative z-10 bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-8 glass-card">
      <div className="absolute inset-0 bg-gradient-to-r from-teal-500/10 to-purple-500/10 rounded-2xl" />
      <div className="relative space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Bot className="h-8 w-8 text-teal-500" />
            <div>
              <h3 className="text-lg font-semibold text-white">AI Assistant</h3>
              <div className="flex items-center text-sm text-gray-400">
                <div className="w-2 h-2 rounded-full bg-teal-500 mr-2 animate-pulse" />
                Online
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <Clock className="h-4 w-4 text-teal-500" />
            <span className="text-sm text-gray-400">Avg. response time: 1s</span>
          </div>
        </div>

        <div 
          ref={chatRef}
          className="space-y-4 h-[300px] overflow-y-auto custom-scrollbar"
        >
          {messages.map((msg, idx) => (
            <Message key={idx} text={msg.text} isBot={msg.isBot} />
          ))}
          {isTyping && (
            <div className="flex justify-start">
              <div className="max-w-[80%] p-3 rounded-lg bg-gray-700/50">
                <TypingIndicator />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}