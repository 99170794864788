import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Search, Calendar, Clock, Tag } from 'lucide-react';
import SEO from '../components/SEO';
import BlogSearch from '../components/blog/BlogSearch';
import BlogCard from '../components/blog/BlogCard';
import { BlogPost } from '../types/blog';
import { fetchBlogPosts } from '../utils/blog';

export default function Blog() {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<BlogPost[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTag, setSelectedTag] = useState<string | null>(null);

  useEffect(() => {
    const loadPosts = async () => {
      const blogPosts = await fetchBlogPosts();
      setPosts(blogPosts);
      setFilteredPosts(blogPosts);
    };
    loadPosts();
  }, []);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    filterPosts(query, selectedTag);
  };

  const handleTagSelect = (tag: string | null) => {
    setSelectedTag(tag);
    filterPosts(searchQuery, tag);
  };

  const filterPosts = (query: string, tag: string | null) => {
    let filtered = [...posts];
    
    if (query) {
      filtered = filtered.filter(post => 
        post.title.toLowerCase().includes(query.toLowerCase()) ||
        post.excerpt.toLowerCase().includes(query.toLowerCase())
      );
    }

    if (tag) {
      filtered = filtered.filter(post => post.tags.includes(tag));
    }

    setFilteredPosts(filtered);
  };

  // Get unique tags from all posts
  const allTags = Array.from(new Set(posts.flatMap(post => post.tags)));

  return (
    <>
      <SEO 
        title="Blog"
        description="Explore our latest insights on AI technology, business automation, and digital transformation."
        keywords={[
          'AI Blog',
          'Business Automation',
          'Digital Transformation',
          'Tech Insights',
          'AI Innovation'
        ]}
      />
      
      <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-32 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-5xl font-bold mb-6">
              <span className="text-gradient">Latest Insights</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Explore our latest thoughts on AI technology, business automation, and digital transformation.
            </p>
          </div>

          {/* Search and Filters */}
          <div className="mb-12">
            <BlogSearch onSearch={handleSearch} />
            
            {/* Tags */}
            <div className="mt-6 flex flex-wrap gap-2">
              <button
                onClick={() => handleTagSelect(null)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  !selectedTag 
                    ? 'bg-teal-500 text-white' 
                    : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                }`}
              >
                All Posts
              </button>
              {allTags.map(tag => (
                <button
                  key={tag}
                  onClick={() => handleTagSelect(tag)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                    selectedTag === tag
                      ? 'bg-teal-500 text-white'
                      : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                  }`}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>

          {/* Blog Posts Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredPosts.map(post => (
              <BlogCard key={post.slug} post={post} />
            ))}
          </div>

          {/* No Results Message */}
          {filteredPosts.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-400 text-lg">
                No posts found matching your criteria.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}