import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar, Clock, Tag } from 'lucide-react';
import type { BlogPost } from '../../types/blog';
import { formatDate, readingTime } from '../../utils/format';

interface BlogCardProps {
  post: BlogPost;
}

export default function BlogCard({ post }: BlogCardProps) {
  return (
    <article className="group relative flex flex-col">
      <div className="relative bg-gray-800 rounded-xl transition-all duration-500 transform-gpu hover:shadow-2xl hover:shadow-teal-500/10 flex flex-col h-full">
        {/* Card Shadow */}
        <div className="absolute -inset-0.5 bg-gradient-to-r from-teal-500 to-purple-500 rounded-xl blur opacity-25 group-hover:opacity-30 transition duration-300" />
        
        {/* Card Content */}
        <div className="relative flex flex-col h-full p-6">
          {/* Cover Image */}
          {post.coverImage && (
            <Link to={`/blog/${post.slug}`} className="block mb-4">
              <img
                src={post.coverImage}
                alt={post.title}
                className="w-full h-48 object-cover rounded-lg transform group-hover:scale-[1.02] transition-transform duration-300"
              />
            </Link>
          )}

          {/* Meta Information */}
          <div className="flex flex-wrap items-center gap-4 text-sm text-gray-400 mb-3">
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-1" />
              <time dateTime={post.publishedAt}>{formatDate(post.publishedAt)}</time>
            </div>
            <div className="flex items-center">
              <Clock className="h-4 w-4 mr-1" />
              <span>{readingTime(post.content)} min read</span>
            </div>
          </div>

          {/* Title */}
          <h2 className="text-xl font-bold text-white mb-3 group-hover:text-teal-400 transition-colors">
            <Link to={`/blog/${post.slug}`}>
              {post.title}
            </Link>
          </h2>

          {/* Excerpt */}
          <p className="text-gray-300 mb-4 flex-grow">
            {post.excerpt}
          </p>

          {/* Tags */}
          <div className="flex flex-wrap gap-2 mt-auto">
            {post.tags.map(tag => (
              <Link
                key={tag}
                to={`/blog?tag=${tag}`}
                className="inline-flex items-center px-2 py-1 rounded-full text-xs bg-teal-500/10 text-teal-400 hover:bg-teal-500/20 transition-colors"
              >
                <Tag className="h-3 w-3 mr-1" />
                {tag}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </article>
  );
}