interface Message {
  isBot: boolean;
  text: string;
}

export const conversations: Message[][] = [
  // Conversation 1: Process Automation
  [
    {
      isBot: true,
      text: "Hi! I'm your AI assistant. How can I help optimize your business operations today?"
    },
    {
      isBot: false,
      text: "We're struggling with manual data entry in our invoicing process. It's taking too much time."
    },
    {
      isBot: true,
      text: "I can help automate your invoicing workflow. Our AI can extract data from documents, validate it, and process invoices automatically - reducing processing time by 85%."
    },
    {
      isBot: false,
      text: "That sounds great, but what about accuracy? We can't afford mistakes."
    },
    {
      isBot: true,
      text: "Our AI achieves 99.9% accuracy through multi-layer validation. It can also flag unusual patterns for human review, giving you both speed and reliability."
    }
  ],

  // Conversation 2: Customer Service Automation
  [
    {
      isBot: true,
      text: "Hello! I'm here to help improve your business operations. What challenges are you facing?"
    },
    {
      isBot: false,
      text: "Our customer service team is overwhelmed with repetitive queries. We need a way to handle them more efficiently."
    },
    {
      isBot: true,
      text: "We can implement an AI-powered customer service solution that automatically handles common queries 24/7, while routing complex issues to your team."
    },
    {
      isBot: false,
      text: "How quickly can we implement this, and will it integrate with our existing systems?"
    },
    {
      isBot: true,
      text: "Implementation takes 2-3 weeks, and we integrate seamlessly with popular platforms like Zendesk and Intercom. Our clients typically see a 70% reduction in response times."
    }
  ],

  // Conversation 3: Supply Chain Optimization
  [
    {
      isBot: true,
      text: "Welcome! I'm your AI assistant. What business challenge can I help you with today?"
    },
    {
      isBot: false,
      text: "Our inventory management is inefficient. We often have stockouts or excess inventory."
    },
    {
      isBot: true,
      text: "Our AI can analyze your historical data and market trends to predict demand patterns with 92% accuracy, optimizing your inventory levels automatically."
    },
    {
      isBot: false,
      text: "Can it handle multiple locations and suppliers?"
    },
    {
      isBot: true,
      text: "Absolutely! Our system manages complex supply chains across multiple locations, considering factors like lead times, seasonal variations, and supplier reliability. Clients typically reduce inventory costs by 30%."
    }
  ],

  // Conversation 4: HR Process Automation
  [
    {
      isBot: true,
      text: "Hi there! How can I help streamline your business processes today?"
    },
    {
      isBot: false,
      text: "Our HR team spends too much time on recruitment screening and employee onboarding."
    },
    {
      isBot: true,
      text: "We can automate your HR workflows with AI. From resume screening to onboarding documentation, we can reduce manual work by 75%."
    },
    {
      isBot: false,
      text: "How does the AI handle candidate screening? We need to ensure fairness."
    },
    {
      isBot: true,
      text: "Our AI is designed with built-in bias detection and uses skill-based assessment criteria. It helps create a more objective screening process while reducing time-to-hire by 60%."
    }
  ],

  // Conversation 5: Financial Analysis Automation
  [
    {
      isBot: true,
      text: "Hello! I'm here to help optimize your business operations. What's your main challenge?"
    },
    {
      isBot: false,
      text: "We need help automating our financial reporting and analysis. It's too manual and time-consuming."
    },
    {
      isBot: true,
      text: "Our AI can automate your financial reporting, providing real-time insights and predictive analytics. It can process data from multiple sources and generate reports automatically."
    },
    {
      isBot: false,
      text: "What about compliance and accuracy? We need to ensure our reports are reliable."
    },
    {
      isBot: true,
      text: "Our system includes built-in compliance checks and audit trails. It maintains 100% accuracy while reducing reporting time by 80%. Plus, it can flag anomalies and potential risks automatically."
    }
  ]
];